import { clsx } from 'clsx';
import { brandContainer, content, mask, subTitle, title } from './brand.css.ts';
import { contentBlock } from './main-waitlist.css.ts';
import { PropsWithChildren } from 'react';

export const Brand = ({ children }: PropsWithChildren) => {
  return (
    <div className={clsx(contentBlock, brandContainer)}>
      <div className={content}>
        <div>
          <p className={title}>
            <span className={mask}>Intelligence</span> network
          </p>
          <p className={title}>& marketplace</p>
        </div>
        <div>
          <p className={subTitle}>Real-time data. Curated research.</p>
          <p className={subTitle}>Actionable intelligence.</p>
        </div>
      </div>
      {children}
    </div>
  );
};
